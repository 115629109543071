import styled           from "styled-components";

import {
    cardShadow,
    hoverEffect,
    lightThemeColor } from "../../utils";

export const YourProjects   = styled.div`
    height              : 100%;
    min-height: 12rem;
    background-color    : white;
    margin              : 0;
    /* width               : 27.5vw; */
    width: 90%;
    padding             : 1rem;
    border-radius       : 1rem;
    box-shadow          : ${cardShadow};
    transition          : 0.4s ease-in-out;
    &:hover {
        box-shadow          : ${hoverEffect};
    }

    @media screen and (min-width: 320px) and (max-width: 1080px) {
        height              : max-content;
        margin-top          : 1rem;
        width               :100%;
    }
`;

export const Project        = styled.div`
    display             : flex;
    align-items         : center;
    margin-bottom       : 0.3rem;
`;

export const Avatar         = styled.div`
    img {
        height          : 4rem;
        width           : 4rem;
        border-radius   : 4rem;
    }
`;

export const Detail         = styled.div`
    margin-left: 1rem;
`;

export const Title          = styled.h3`
    font-weight: 500;
    @media screen and (min-width: 320px) and (max-width: 1080px) {
        font-size: 1rem;
    }
`;

export const SubTitle       = styled.h5`
    font-weight: 300;
`;

export const AllProjects    = styled.h5`
    text-align: end;
    color: ${lightThemeColor};
    cursor: pointer;
`;