import styled           from "styled-components";
import { appBGColor }   from "../utils";

export const Container = styled.div`
    display             : flex;
    height              : 97vh;
    /* min-height          : 38rem; */
    background-color    : ${appBGColor};
    border-radius       : 2rem;

    @media screen and (min-width: 320px) and (max-width: 1080px) {
        flex-direction      : column;
    }
`;