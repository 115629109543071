import React, { useState }  from 'react';
import InfoSection          from '../components/InfoSection';

import {
  adminHomeObj1,
  adminHomeObj2,
  adminHomeObj3,
} from '../components/InfoSection/Data';

function Home() {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
      <InfoSection {...adminHomeObj1} />
      <InfoSection {...adminHomeObj2} />
      <InfoSection {...adminHomeObj3} />
    </>
  );
}

export default Home;
