import React            from 'react';
import { FiSearch }     from 'react-icons/fi';
import {
    Icon,
    Input,
    InputContainer,
    NavbarContainer,
    Text }              from './index.elements'

const AuthNavbar = () => {
  return (
    <NavbarContainer>
        <Text>
            Good morning,
            <span> Miguel</span>
        </Text>
        <InputContainer>
            <Icon>
                <FiSearch/>
            </Icon>
            <Input type='text' placeholder='Search for projects'></Input>
        </InputContainer>
    </NavbarContainer>
  )
}

export default AuthNavbar;