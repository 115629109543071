import                                    './App.css';
import React, { useEffect }         from  'react'
import MainContent                  from  './../components/MainContent';
import Sidebar                      from  '../components/AuthSidebar';
import { Container }                from  './index.elements';
import { useDispatch }              from  'react-redux'
import { Routes, Route, Navigate }  from  'react-router-dom'
import { checkUserSession }         from  './../redux/User/user.actions';

//HOC
import WithAuth                     from './../hoc/withAuth';

//Pages
import SigninPage                   from './../pages/signin';
import Home                         from './../pages';
import PageNotFoundPage             from './../pages/pagenotready';
import RecoveryPage                 from './../pages/recovery';


//Layouts
import UnauthenticatedLayout        from './../layouts/UnauthenticatedLayout';
import LimitedLayout                from './../layouts/LimitedLayout';


const App = props => {
  const dispatch = useDispatch();

  useEffect(() => {
      dispatch(checkUserSession());
  }, [dispatch])

  return (
          <Routes>
            <Route exact  path='/'                    element={
                <UnauthenticatedLayout>
                    <Home />
                </UnauthenticatedLayout>
            } />

            <Route        path='/signin'              element={
                <LimitedLayout label="Home"           destination="/">
                    <SigninPage />
                </LimitedLayout>
            } />

            <Route exact  path="/privacy.html"        element={
                <LimitedLayout label="Home"           destination="/">
                  <Navigate push to="/privacy.html"/>
                </LimitedLayout>
            } />

            <Route exact  path='/dashboard'           element={
                <WithAuth>
                  <Container>
                    <Sidebar />
                    <MainContent />
                  </Container>
                </WithAuth>
            } />

            <Route exact  path='/projects'           element={
                <WithAuth>
                  <Container>
                    <Sidebar />
                    <h1>Projects</h1>
                  </Container>
                </WithAuth>
            } />

            <Route exact  path='/invoices'           element={
                <WithAuth>
                  <Container>
                    <Sidebar />
                    <h1>Invoices</h1>
                  </Container>
                </WithAuth>
            } />

            <Route exact  path='/reports'           element={
                <WithAuth>
                  <Container>
                    <Sidebar />
                    <h1>Reports</h1>
                  </Container>
                </WithAuth>
            } />

            <Route exact  path='/users'           element={
                <WithAuth>
                  <Container>
                    <Sidebar />
                    <h1>Manage Users</h1>
                  </Container>
                </WithAuth>
            } />
            

            <Route        path='/recovery'            element={
                <LimitedLayout label="Home"           destination="/">
                    <RecoveryPage />
                </LimitedLayout>
            } />


            
            <Route        path='*'                    element={
                <LimitedLayout label="Home"           destination="/" >
                  <PageNotFoundPage />
                </LimitedLayout>
            } />
          </Routes>
          );
}

export default App;










// const App = props => {
//   return (
//     <Container>
//         <Sidebar />
//         <MainContent />
//     </Container>
//   )
// }

// export default App;