export const adminHomeObj1 = {
  id            : 'update1',
  destination   : 'update2',
  isScroll      : true,
  lightBg       : false,
  lightText     : true,
  lightTextDesc : true,
  topLine       : 'Launch',
  headline      : 'Getting Started',
  description   : 'Luckey Logic Admin subdomain launches April 1, 2022.',
  buttonLabel   : 'Next Update',
  imgStart      : false,
  img           : require('../../assets/images/launch.svg').default,
  alt           : 'Launch',
  dark          : true,
  primary       : true,
  darkText      : false
};

export const adminHomeObj2 = {
  id            : 'update2',
  destination   : 'update3',
  isScroll      : true,
  lightBg       : true,
  lightText     : false,
  lightTextDesc : false,
  topLine       : 'Connections',
  headline      : 'Back End Connected',
  description   : 'Luckey Logic back end now connected to admin sub domain and public domain April 1, 2022.',
  buttonLabel   : 'Next Update',
  imgStart      : true,
  img           : require('../../assets/images/connection.svg').default,
  alt           : 'Connection',
  dark          : false,
  primary       : false,
  darkText      : true
};

export const adminHomeObj3 = {
  id            : 'update3',
  destination   : 'update4',
  isScroll      : true,
  lightBg       : false,
  lightText     : true,
  lightTextDesc : true,
  topLine       : 'First User',
  headline      : 'Grand Father Luck',
  description   : 'First Admin user added to luckey logic April 1, 2022',
  buttonLabel   : 'Next Update',
  imgStart      : false,
  img           : require('../../assets/images/firstUser.svg').default,
  alt           : 'First User',
  dark          : true,
  primary       : true,
  darkText      : false
};

// alt           "Launch"
// buttonLabel   "Next Update"
// date          April 1, 2022 at 12:00:00 AM UTC-4
// description   "Luckey Logic Admin subdomain launches"
// destination   ""
// headline      "Getting Started"
// id            "A2dtRD9MO3iOW9gSRb40"
// img           ""
// topLine       "Launch"