import styled from "styled-components";
import { appGradientHLD } from "../../utils";

export const Container = styled.div`
    width                       : 80%;
    background                  : ${appGradientHLD};
    border-top-right-radius     : 2rem;
    border-bottom-right-radius  : 2rem;
    margin                      : 1rem 1rem 1rem 1rem;
    @media screen and (min-width: 320px) and (max-width: 1080px) {
        display                     : flex;
        flex-direction              : column;
        width                       : 100%;
        margin                      : 1rem 0 0 0;
        border-top-left-radius      : 2rem;
        border-bottom-left-radius   : 2rem;
        /* padding: -1rem; */
    }
`;

export const SubContainer = styled.div`
    /* margin                      : 0.5rem 0; */
    height                      : 80%;
    width                       : 100%;
    display                     : flex;
    flex-direction              : column;
    gap                         : 1rem;
    /* flex-wrap                   : wrap; */
    @media screen and (min-width: 320px) and (max-width: 1080px) {
        height                  : 100%;
        
        /* flex-wrap               : wrap; */
    }
`;

export const SectionOne = styled.div`
    display                     : flex;
    /* background-color: yellow; */
    justify-content             : space-between;
    height                      : 100%;
    min-height: 16rem;
    width                       : 100%;
    gap                         : 1rem;

    @media screen and (min-width: 320px) and (max-width: 1080px) {
        flex-direction: column;
        align-items: center;
        height: max-content;
    }
`;


export const ColumnOne1 = styled.div`
    width: 50%;
    display                     : flex;
    height: 100%;
    gap                         : 1rem;

    @media screen and (min-width: 320px) and (max-width: 1080px) {
        flex-direction              : column;
        justify-content             : center;
        align-items                 : center;
        gap                         : 1rem;
        width                       : 100%;
    }
`;


export const ColumnTwo1 = styled.div`
    display                     : flex;
    flex-direction              : column;
    height                      : 100%;
    width                       : 50%;

    @media screen and (min-width: 320px) and (max-width: 1080px) {
        height                      : max-content;
        width: 75%;
        justify-content             : center;
        align-items                 : center;
    }
`;



export const SectionTwo = styled.div`
    display                     : flex;
    gap                         : 1rem;
    /* height                      : 26vh; */
    height: 100%;
    /* background-color: yellow; */
    min-height: 16rem;
    
    @media screen and (min-width: 320px) and (max-width: 1080px) {
        flex-direction              : column;
        height                      : max-content;
        width                       : 100%;
    }
`;


export const ColumnOne2 = styled.div`
    width: 100%;
    /* background-color: green; */
    @media screen and (min-width: 320px) and (max-width: 1080px) {
        display                     : flex;
        flex-direction              : column;
        justify-content             : center;
        align-items                 : center;
        width                       : 100%;
    }
`;

export const COlumnTwo2 = styled.div`
    width: 100%;
    @media screen and (min-width: 320px) and (max-width: 1080px) {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
`;


