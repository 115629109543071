import styled           from "styled-components";
import {
    hoverEffect,
    darkThemeColor, 
    linkColor,
    lightThemeColor}    from "../../utils";

    export const JoinChannel    = styled.div`
        max-width: 33rem;
        background-color    : ${darkThemeColor};
        height              : 30%;
        margin-top          : 3.5rem;
        border-radius       : 1rem;
        display             : flex;
        align-items         : center;
        transition          : 0.4s ease-in-out;
        &:hover {
            box-shadow          : ${hoverEffect};
        }

        @media screen and (min-width: 320px) and (max-width: 1080px) {
        width               : 80%;
        margin-top          : 2rem;
        margin-bottom       : 2rem;
        height              : max-content;
    }
    `;

    export const CardContent    = styled.div`
        margin              : 1rem;
        width               : 100%;
        display             : flex;
        align-items         : center;
        justify-content     : space-between;
        
        @media screen and (min-width: 320px) and (max-width: 1080px) {
        flex-direction: column;
        gap: 1rem;
    }
    `;

    export const Slack          = styled.div`
        display             : flex;
    `;

    export const SlackLogo      = styled.div`
        margin-right        : 0.7rem;
        display             : flex;
        justify-content     : center;
        align-items         : center;

        svg {
            color               : white;
            height              : 3rem;
            width               : 3rem;
        }
    `;

    export const SlackText      = styled.div`
        color               : white;
    `;

    export const SlackHead      = styled.h2`
        font-weight         : 500;
    `;

    export const SlackFoot      = styled.h5`
        color               : ${linkColor};
        font-weight         : normal;
    `;

    export const SlackJoin      = styled.button`
        background-color    : ${lightThemeColor};
        border              : none;
        outline             : none;
        padding             : 1rem 2rem;
        color               : white;
        border-radius       : 0.5rem;
        font-size           : 1.2rem;
        font-weight         : bold;
        cursor              : pointer;
    `;