import styled           from "styled-components";

import {
    cardShadow,
    hoverEffect }       from "../../utils";

export const InvoicesContainer = styled.div`
    height: 50%;
    /* background-color: aqua; */
    max-width: 33rem;

    @media screen and (min-width: 320px) and (max-width: 1080px) {
        height                      : max-content;
        display                     : flex;
        justify-content             : center;
        align-items                 : center;
        flex-direction              : column;
        width                       : 100%;
        max-width: 100%;
    }
`;

export const InvoiceContainer = styled.div`
    max-width: 33rem;
    border-radius: 1rem;
    margin-top: 0.5rem;
    background-color: white;
    height: 10rem;
    box-shadow          : ${cardShadow};
    transition          : 0.4s ease-in-out;
    &:hover {
        box-shadow          : ${hoverEffect};
    }
    @media screen and (min-width: 320px) and (max-width: 1080px) {
        width               : 75%;
        height              : auto;
        max-width           : 100%;
        display             : flex;
        justify-content     : center;
        align-items         : center;
    }
`;

export const CardContent = styled.div`
    @media screen and (min-width: 320px) and (max-width: 1080px) {
        margin                  : 2rem 0;
    }
`;

export const Invoice = styled.div`
    display                 : flex;
    align-items             : center;
    /* justify-content         : space-around; */
    margin                  : 0.4rem;
    padding-top             : 0.4rem;

    @media screen and (min-width: 320px) and (max-width: 1080px) {
        flex-direction          : row;
        gap                     : 1rem;
    }
`;

export const Info = styled.div`
    display                 : flex;
    align-items             : center;
    width                   : 50%;

    @media screen and (min-width: 320px) and (max-width: 1080px) {
        flex-direction          : column;
        width                   : 100%;
        text-align              : center;
    }
`;

export const Avatar = styled.div`
    img {
        height                  : 3.5rem;
        width                   : 3.5rem;
        border-radius           : 3.5rem;
    }
`;

export const TextContainer = styled.div`
    margin-left             : 0.5rem;
`;

export const Title = styled.h4`

`;

export const SubTitle = styled.h5`
    font-weight             : 400;
`;

export const Container = styled.div`
    display                 : flex;
    justify-content         : space-between;
    width                   : 30%;
    align-items             : center;

    @media screen and (min-width: 320px) and (max-width: 1080px) {
        width                   : 100%;
        flex-direction          : column;
        gap                     : 0.6rem;
    }
`;

export const Price = styled.div`
    min-width: 7rem;
`;