import React            from 'react';
import Badge            from './../Badge';
import AvatarImage1     from './../../assets/images/william_johnson.png';
import {
    Avatar,
    CardContent,
    Detail,
    InfoContainer,
    Info,
    InfoName,
    InfoUpdate,
    Price,
    PriceContainer,
    ProjectInfo,
    RecommendProject, 
    Title}  from './index.elements';

const ProjectRecommendation = () => {
  return (
    <RecommendProject>
        <CardContent>
            <Detail>
                <InfoContainer>
                    <Avatar>
                        <img src={AvatarImage1} alt="" />
                    </Avatar>
                    <Info>
                        <InfoName>William Johnson</InfoName>
                        <InfoUpdate>Updated 10m ago</InfoUpdate>
                    </Info>
                </InfoContainer>
                <Badge content="Design" />
            </Detail>
            <Title>
            Need a designer to form branding essentials for my business.
            </Title>
            <ProjectInfo>
            Looking for a talented brand designer to create all the branding materials for my new startup.
            </ProjectInfo>
            <PriceContainer>
                <Price>$8,700/month</Price>
                <Badge content="Full Time" clean />
            </PriceContainer>
        </CardContent>
    </RecommendProject>
  )
}

export default ProjectRecommendation