import React                                from 'react'
import { lightThemeColor, darkThemeColor }  from '../../utils'
import { Div } from './index.elements'

const Badge = ({ content, clean = false, glow = false, paid = false, late = false}) => {
  return (
    <Div clean={clean} glow={glow} paid={paid} late={late} >{content}</Div>
  )
}

export default Badge