import styled                   from "styled-components";
import { Link as LinkR }        from 'react-router-dom';
import {
    contactContainerBGColor,
    contactContainerFGColor,
    darkThemeColor,
    sidebarBGColor,
    linkColor }                 from "../../utils";

export const Container              = styled.div`
    width               : 20%;
    min-width           : 15rem;
    height              : 100% !important;
    min-height: 35rem;
    border-radius       : 2rem;
    background-color    : ${sidebarBGColor};
    display             : flex;
    flex-direction      : column;
    align-items         : center;
    gap                 : 1rem;

    @media screen and (min-width: 320px) and (max-width: 1080px) {
        width: 100%;
        min-height: auto;
        height: max-content !important;
    }
`;

export const ProfileContainer       = styled.div`
    display             : flex;
    justify-content     : center;
    align-items         : center;
    flex-direction      : column;
`;


export const NavLogo = styled(LinkR)`
  color           : #fff;
  /* justify-self    : flex-start; */
  cursor          : pointer;
  font-size       : 1.5rem;
  /* display         : flex; */
  align-items     : center;
  margin              : 0 0 0.5rem 0;
  font-weight     : bold;
  text-decoration : none;

  @media screen and (min-width: 320px) and (max-width: 1080px) {
        width: auto;
    }
`;

export const NavLogoImage = styled.img`
/* height            : 5rem; */
width: 100%;
margin              : 0.5rem 0 0.5rem 0;
`;

export const Avatar                 = styled.img`
    /* height              : 7rem; */
    max-height: 7rem;
    max-width: 7rem;
    border-radius       : 7rem;
    margin-top          : 0rem;
`;

export const Name                   = styled.h1`
    color               : white;
    font                : 1.5rem;
    font-weight         : 400;
    margin              : 0.8rem 0 0.5rem 0;
`;

export const LinksContainer = styled.div`
    background-color    : ${darkThemeColor};
    height              : 100%;
    width               : 100%;
    border-radius       : 2rem;
`;

export const Links = styled.ul`
    list-style          : none;
    display             : flex;
    flex-direction      : column;
    padding-top         : 1rem;
    height              : 60%;
`;

// export const NavLink = styled(LinkR)`
//     text-decoration: none;
// `;

export const NavLink = styled(LinkR)`
display: flex;
flex-direction: row;
  color: #fff;
  text-decoration: none;
  gap: 1rem;
  outline: none;

  &:visited {
    color: #fff;
  }

  &:hover {
    color: #fff;
  }

  &:focus {
    outline: none;
  }

  &:active {
    color: #fff;
  }
`;


export const Link = styled.li`
    margin-left         : 25%;
    margin-bottom       : 1rem;
    display             : flex;
    gap                 : 1rem;
    color               : ${linkColor};
    cursor              : pointer;

    h3 {
        font-weight         : 300;
    }

    svg {
        font-size           : 1.1rem;
        margin-top          : 1%;
    }
`;

export const ContactContainer = styled.div`
    width               : 60%;
    background-color    : ${contactContainerBGColor};
    color               : ${contactContainerFGColor};
    height              : 3rem;
    margin              : auto auto;
    border-radius       : 1rem;
    display             : flex;
    flex-direction      : column;
    padding             : 1rem;
    a {
        color               : white;
        text-decoration     : none;
    }

    @media screen and (min-width: 320px) and (max-width: 1080px) {
        margin: 1rem auto 2rem auto;
    }
`;