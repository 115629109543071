import React            from 'react';
import { TitleText }    from '../Common/TitleText/index.elements';
import Badge            from '../Badge';
import {
    Avatar,
    CardContent,
    Container,
    Info,
    Invoice,
    InvoiceContainer,
    InvoicesContainer,
    Price, 
    SubTitle, 
    TextContainer,
    Title }             from './index.elements';
import AvatarImage1     from './../../assets/images/david_nguyen.png';
import AvatarImage2     from './../../assets/images/molly_odonnell.png';

const Invoices = ({ text }) => {
  return (
    <InvoicesContainer>
        <TitleText>{text}</TitleText>
        <InvoiceContainer>
            <CardContent>
                <Invoice>
                    <Info>
                        <Avatar>
                            <img src={AvatarImage1} alt="" />
                        </Avatar>
                        <TextContainer>
                            <Title>David Nguyen</Title>
                            <SubTitle>David's Corner Market</SubTitle>
                        </TextContainer>
                    </Info>
                    <Container>
                        <Badge content="Paid" paid />
                        <Price>$ 1200.87</Price>
                    </Container>
                </Invoice>
                <Invoice>
                    <Info>
                        <Avatar>
                            <img src={AvatarImage2} alt="" />
                        </Avatar>
                        <TextContainer>
                            <Title>Molly O'Donnell</Title>
                            <SubTitle>Molly's Majestic Pups</SubTitle>
                        </TextContainer>
                    </Info>
                    <Container>
                        <Badge content="Late" late />
                        <Price>$ 500,000.00</Price>
                    </Container>
                </Invoice>
            </CardContent>
        </InvoiceContainer>
    </InvoicesContainer>
  )
}

export default Invoices