import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const mapState = ({ user }) => ({
    currentUser: user.currentUser
});

const useAuth = props => {
    const { currentUser } = useSelector(mapState);
    const navigate = useNavigate();

    useEffect(() => {
        if (!currentUser) {
            navigate('/signin');
        }
        // if (!currentUser.isLuckey) {
        //     console.log("Is not Luckey")
        // } else {
        //     console.log("Is Luckey")
        // }
    }, [currentUser]);

    return currentUser;
}



export default useAuth;
