import React            from 'react';
import AvatarImage1     from './../../assets/images/david_nguyen.png';
import AvatarImage2     from './../../assets/images/molly_odonnell.png';
import {
    AllProjects,
    Avatar,
    Detail,
    Project,
    SubTitle,
    Title,
    YourProjects } from './index.elements'

const Projects = () => {
  return (
    <YourProjects>
        <Project>
            <Avatar>
                <img src={AvatarImage1} alt="" />
            </Avatar>
            <Detail>
                <Title>Business profile for carryout</Title>
                <SubTitle>1 day remaining</SubTitle>
            </Detail>
        </Project>
        <Project>
            <Avatar>
                <img src={AvatarImage2} alt="" />
            </Avatar>
            <Detail>
                <Title>Website design for breeder</Title>
                <SubTitle>26 days remaining</SubTitle>
            </Detail>
        </Project>
        <AllProjects>See all projects</AllProjects>
    </YourProjects>
  )
}

export default Projects