import React, {
  useState,
  useEffect }                 from 'react';

import {
  useDispatch,
  useSelector,
  connect }                   from 'react-redux';
import Badge                  from '../Badge';
import AvatarImage            from './../../assets/images/avatarImage.jpg';
import LuckeyLogicLogo        from '../../assets/images/luckey-logic-logo.svg';

import {
  AiOutlineDashboard   as DashboardIcon,
  AiOutlineCode   as ProjectsIcon,
  AiOutlineDollar as InvoicesIcon,
  AiOutlineTeam   as UsersIcon,
  AiOutlineLogout as SignOutIcon,
  AiOutlinePieChart }         from 'react-icons/ai';
import {
  Avatar,
  ContactContainer,
  Container,
  Link,
  Links,
  NavLink,
  LinksContainer,
  Name,
  NavLogo,
  NavLogoImage,
  ProfileContainer }  from './index.elements';

  import { signOutUserStart }         from '../../redux/User/user.actions';
// import { NavLink } from 'react-router-dom';

const mapStateToProps = ({ user }) => ({
  currentUser: user.currentUser
});


const AuthSidebar = () => {
  const dispatch                  = useDispatch();
  const {currentUser}             = useSelector(mapStateToProps);
  const [click, setClick]         = useState(false);
  const signOut                   = () => {
    dispatch(signOutUserStart());
  };

  const handleClick               = () => setClick(!click);
  const closeMobileMenu           = () => setClick(false);

  const [scrollnav, setScrollNav] = useState(false);

  const changeNav = () => {
    if (window.scrollY >= 80) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', changeNav);
    return () => {
      window.removeEventListener('scroll', changeNav);
    };
  }, []);

  const toggleHome = () => {
    // scroll.scrollToTop();
  };

  return (
    <Container>
      <ProfileContainer>
        <NavLogo onClick={toggleHome} to='/'>
          <NavLogoImage src={LuckeyLogicLogo} />
        </NavLogo>
        
        <Avatar src={AvatarImage} />
        <Name>{currentUser.displayName}</Name>
        <Badge content="Pro Level" />
      </ProfileContainer>
      <LinksContainer>
        <Links>
          <Link >
            <NavLink to='/dashboard'>
              <DashboardIcon />
              <h3>Dashboard</h3>
            </NavLink>
          </Link>
          <Link>
            <NavLink to='/projects' >
              <ProjectsIcon />
              <h3>Projects</h3>
            </NavLink>
          </Link>
          <Link>
            <NavLink to='/invoices' >
              <InvoicesIcon />
              <h3>Invoices</h3>
            </NavLink>
          </Link>
          <Link>
            <NavLink to='/reports' >
              <AiOutlinePieChart />
              <h3>Reports</h3>
            </NavLink>
          </Link>
          <Link>
            <NavLink to='/users' >
              <UsersIcon />
              <h3>Manage Users</h3>
            </NavLink>
          </Link>
          <Link onClick={signOut}>
            <SignOutIcon />
            <h3>Sign Out</h3>
          </Link>
        </Links>
        <ContactContainer>
          <span>Having troubles?</span>
          <a href="#">Contact Us</a>
        </ContactContainer>
      </LinksContainer>
    </Container>
  )
}

export default connect(mapStateToProps, null)(AuthSidebar);
