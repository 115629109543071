import React from 'react'
import { IoStatsChart } from 'react-icons/io5'
import { CardContent, Chart, Earning, EarningsCard, EarningsIncrease, EarningsText } from './index.elements'

const Earnings = () => {
  return (
    <EarningsCard>
        <CardContent>
            <Chart>
                <IoStatsChart />
            </Chart>
            <EarningsText>Earnings</EarningsText>
            <Earning>$8,954</Earning>
            <EarningsIncrease>+ 10% since last month</EarningsIncrease>
        </CardContent>
    </EarningsCard>
  )
}

export default Earnings