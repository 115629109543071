import styled           from "styled-components";

import {
    cardShadow,
    hoverEffect,
    projectRecInfoColor,
    projectRecPriceBGColor}   from "../../utils";

export const RecommendProject   = styled.div`
    border-radius       : 1rem;
    height              : 80%;
    padding             : 1rem;
    background-color    : white;
    /* width               : 27.5vw; */
    width: 90%;
    box-shadow          : ${cardShadow};
    transition          : 0.4s ease-in-out;
    &:hover {
        box-shadow          : ${hoverEffect};
    }

    @media screen and (min-width: 320px) and (max-width: 1080px) {
        width               : 80%;
        height              : max-content;
        margin              : 2rem 0;
    }
`;

export const CardContent        = styled.div`
    margin              : 0.4rem;
`;

export const Detail             = styled.div`
    display             : flex;
    justify-content     : space-around;
    align-items         : center;

    @media screen and (min-width: 320px) and (max-width: 1080px) {
        flex-direction      : column;
        justify-content     : flex-start;
        align-items         : flex-start;
        gap                 : 0.2rem;
        margin-bottom       : 1rem;
    }
`;

export const InfoContainer      = styled.div`
    display             : flex;
    align-items         : center;
`;

export const Avatar             = styled.div`
        margin-right    : 1rem;

    img {
        height          : 5rem;
        width           : 5rem;
        border-radius   : 5rem;
    }
`;

export const Info               = styled.div`

`;

export const InfoName           = styled.h3`
    font-weight         : 500;
`;

export const InfoUpdate         = styled.h5`
    font-weight         : 300;
`;

export const Title              = styled.h4`
    font-weight         : 500;
`;

export const ProjectInfo        = styled.p`
    margin              : 0;
    padding             : 0;
    font-size           : 0.9rem;
    color               : ${projectRecInfoColor};
    margin-bottom       : 0.5rem;
`;

export const PriceContainer     = styled.div`
    display             : flex;
    align-items         : center;
    justify-content     : space-between;
    margin              : 0 1rem;
    padding             : 0.5rem 1rem;
    border-radius       : 1rem;
    background-color    : ${projectRecPriceBGColor};

    @media screen and (min-width: 320px) and (max-width: 1080px) {
        flex-direction: column;
        gap: 0.4rem;
    }
`;

export const Price              = styled.div`

`;