export const lightThemeColor            = '#6100D4';
export const darkThemeColor             = '#162349';
export const sidebarBGColor             = '#091322';
export const linkColor                  = '#E4E4E4';
export const contactContainerBGColor    = '#091222';
export const contactContainerFGColor    = '#C4C4C4';
export const navbarTextColor            = '#484258';
export const navbarIconBGColor          = '#dce4ff';
export const navbarSVGColor             = '#555555';
export const navbarInputColor           = '#464646';
export const badgeGlowColor             = '#2f233D';
export const badgeGlowBGColor           = 'rgba(109,134, 245, 0.192)';
export const badgePaidBGColor           = '#70E00041';
export const badgePaidColor             = '#70E000';
export const badgeLateBGColor           = '#FF595E41';
export const badgeLateColor             = '#FF595E';
export const hoverEffect                = 'rgba(0, 0, 0, 0.56) 0px 22px 70px 4px';
export const cardShadow                 = 'rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px';
export const earningsIncreaseBGColor    = 'rgba(0, 0, 0, 0.2)';
export const cardBGColor                = 'rgba(183, 194, 243, 0.3)';
export const infoSubtitleColor          = '#333333';
export const projectRecInfoColor        = '#3B3B3B';
export const projectRecPriceBGColor     = 'rgba(146, 166, 255, 0.3)';
export const appBGColor                 = 'rgba(103, 214, 255, 1)';
export const appGradientAngle           = 'linear-gradient( 108deg, rgba(25, 51, 86, 1) 0%, rgba(103, 214, 255, 1) 100% )';
export const appGradientHLD             = 'linear-gradient( 270deg, rgba(25, 51, 86, 1) 0%, rgba(103, 214, 255, 1) 100% )';
export const appGradientHDL             = 'linear-gradient( 90deg,  rgba(25, 51, 86, 1) 0%, rgba(103, 214, 255, 1) 100% )';
export const appGradientVLD             = 'linear-gradient( 0deg,   rgba(25, 51, 86, 1) 0%, rgba(103, 214, 255, 1) 100% )';
export const appGradientVDL             = 'linear-gradient( 180deg, rgba(25, 51, 86, 1) 0%, rgba(103, 214, 255, 1) 100% )';
