import styled           from 'styled-components';
import {
    lightThemeColor,
    badgeGlowColor,
    badgeGlowBGColor,
    badgePaidColor,
    badgePaidBGColor, 
    badgeLateColor,
    badgeLateBGColor}  from '../../utils';

export const Div = styled.div`
    padding             : 0.3rem 1rem;
    border-radius       : 1rem;
    font-weight         : 500;
    color               : white;
    background-color    : ${lightThemeColor};
    cursor              : pointer;

    ${({ clean }) =>
        clean &&
            `
            color               : ${lightThemeColor};
            border              : 0.05rem solid ${lightThemeColor};
            background-color    : transparent;
            `}

    ${({ glow }) =>
        glow &&
            `
            font-size           : 0.8rem;
            padding             : 0.2rem 0.5rem;
            font-weight         : normal;
            color               : ${badgeGlowColor};
            background-color    : ${badgeGlowBGColor};
            `}

    ${({ paid }) => 
        paid &&
            `
            color               : ${badgePaidColor};
            background-color    : ${badgePaidBGColor};
            `
    }

    ${({ late }) => 
        late &&
            `
            color               : ${badgeLateColor};
            background-color    : ${badgeLateBGColor};
            `
    }
`;