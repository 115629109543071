import React          from 'react'
import Earnings       from '../Earnings'
import Info           from '../Info'
import Invoices       from '../Invoices'
import AuthNavbar         from '../AuthNavbar'
import Projects       from '../Projects'
import { TitleText }  from '../Common/TitleText/index.elements'
import {
  ColumnOne1,
  ColumnOne2,
  ColumnTwo1,
  COlumnTwo2,
  Container,
  SectionOne,
  SectionTwo,
  SubContainer }  from './index.elements'
import JoinSlack from '../JoinSlack'
import ProjectRecommendation from '../ProjectRecommendation'

const MainContent = () => {
  return (
    <Container>
      <AuthNavbar />
      <SubContainer>
        <SectionOne>
          <ColumnOne1>
            <Earnings />
            <Info />
          </ColumnOne1>
          <ColumnTwo1>
            <TitleText>Your Projects</TitleText>
            <Projects />
          </ColumnTwo1>
        </SectionOne>
        <SectionTwo>
          <ColumnOne2>
            <Invoices text="Recent invoices"/>
            <JoinSlack />
          </ColumnOne2>
          <COlumnTwo2>
            <TitleText>Recommended Projects</TitleText>
            <ProjectRecommendation />
          </COlumnTwo2>
        </SectionTwo>
      </SubContainer>
    </Container>
  )
}

export default MainContent